import React, {ReactNode, useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {abortSignIn} from "store/actions/auth.actions";
import {ConfigInterface} from "interfaces/config.interface";
import {useTranslation} from "react-i18next";
import {AppState} from "store/reducers";
import {useLocation} from "react-router";
import {AuthReducerActionType} from "store/reducers/auth.reducer";
import {Version} from "version";

interface LayoutProps {
    config: ConfigInterface,
    children: ReactNode,
    abortSignIn: () => void
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {

    const {t} = useTranslation();

    const {config, abortSignIn, children} = props;
    const {ui_settings} = config;

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: AuthReducerActionType.REMOVE_ERRORS,
        })
    }, [location, dispatch])

    if (config.isLoaded && config.isEmpty) {
        return <p>Et ole kirjautumassa mihinkään palveluun!</p>
    }

    return <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
            {config && ui_settings && ui_settings.logo_url &&
            <img className="mx-auto h-16 w-auto" src={ui_settings.logo_url} alt=""/>}
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-xl">
            {children}
        </div>
        <button
            className="mt-4 text-center cursor-pointer font-medium text-gray-600 hover:text-gray-500 focus:outline-none focus:underline transition ease-in-out duration-150"
            onClick={() => abortSignIn()}>
            {t("common.return")}
        </button>
        <span className="mt-1 text-center font-medium text-gray-400 text-xs">Versiotunniste: {Version.git_commit}</span>
    </div>
};

const mapStateToProps = (state: AppState) => ({
    config: state.config
});

const mapDispatchToProps = (dispatch: any) => ({
    abortSignIn: () => dispatch(abortSignIn())
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
