import {ConfigInterface} from "interfaces/config.interface";

const initState: ConfigInterface = {
    available_password_recovery_methods: [],
    login_challenge: "",
    allow_register: true,
    allow_remember_me: false,
    available_login_methods: [],
    default_view: "",
    external_id_labels: {},
    login_token: "",
    redirect_to: "",
    uid_labels: {},
    ui_settings: {
        primary_color: "#FCD20E",
        secondary_color: "#C8C8C8",
    },
    error: null,
    isLoaded: false,
    isEmpty: true,
    first_load: true
};

export enum ConfigReducerActionType {
    APPLY_CONFIG = "apply_config",
    FIRST_TIME_LOAD = "first_time_load",
    APPLY_CONFIG_ERROR = "apply_config_error",
}

const configReducer = (state = initState, action: { type: ConfigReducerActionType, config?: ConfigInterface, error: any }) => {
    switch (action.type) {
        case ConfigReducerActionType.APPLY_CONFIG:
            localStorage.setItem('login_challenge', action.config?.login_challenge || '');
			sessionStorage.removeItem('handled_default_view_login_challenge');
            return {...state, ...action.config, error: null, isLoaded: true, isEmpty: false};
        case ConfigReducerActionType.FIRST_TIME_LOAD:
            return {...state, first_load: false};
        case ConfigReducerActionType.APPLY_CONFIG_ERROR:
            console.log(action.error);
            return {...state, error: action.error.message, isLoaded: true, isEmpty: true};
        default:
            return state;
    }
};

export default configReducer;
