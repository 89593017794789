import {combineReducers} from "redux";
import authReducer, {AuthInterface} from "./auth.reducer";
import configReducer from "./config.reducer";
import {ConfigInterface} from "interfaces/config.interface";

export default combineReducers({
    auth: authReducer,
    config: configReducer
});

export interface AppState {
    auth: AuthInterface,
    config: ConfigInterface
}
