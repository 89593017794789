import React from "react";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Done: React.FC = () => {

    const {t} = useTranslation();

    return <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div className="font-bold text-2xl mb-2 text-gray-700">{t("signup.confirmed.title")}</div>
        <p className="font-normal text-sm mb-2 text-gray-700">{t("signup.confirmed.text")}</p>
        <div className="mt-6">
            <span className="block w-full rounded-md shadow-sm">
                <Link to="/signin"
                      className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                  {t("common.signin")}
                </Link>
            </span>
        </div>
    </div>
};

export default withRouter(Done);
