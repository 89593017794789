import React from 'react'
import SocialLogin from 'react-social-login'

interface SocialLoginInterface {
    appId: string;
    autoCleanUri?: boolean;
    autoLogin?: boolean;
    gatekeeper?: string;
    getInstance?: () => any;
    onLoginFailure?: () => any;
    onLoginSuccess?: () => any;
    onLogoutFailure?: () => any;
    onLogoutSuccess?: () => any;
    provider?: any;
    redirect?: any;
    scope?: string[] | string;
    children: React.ReactNode;
    triggerLogin?: any;
}

const SocialButton: React.FC<SocialLoginInterface> = (props: SocialLoginInterface) => {

    const {triggerLogin, ...buttonProps} = props;

    return <span className="w-full inline-flex rounded-md shadow-sm">
        <button
            type="button"
            className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
            aria-label="Kirjaudu Facebookilla"
            onClick={props.triggerLogin}
            {...buttonProps}>
            {props.children}
        </button>
    </span>;
}

export default SocialLogin(SocialButton);
