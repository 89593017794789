import React from "react";
import {useTranslation} from "react-i18next";
import Layout from "../layout.component";

const SignOutPage = (props: any) => {

    const {t} = useTranslation();

    return <Layout>
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="font-bold text-2xl mb-2 text-gray-700">{t("signout.title")}</div>
            <p className="font-normal text-sm mb-2 text-gray-700">{t("signout.text")}</p>
            <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                   <button
                       type="submit"
                       className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                     {t("common.return")}
                   </button>
                </span>
            </div>
        </div>
    </Layout>
};

export default SignOutPage;
