export interface AuthInterface {
    error_message: string |null,
    profile: {
        isEmpty: boolean,
        isLoaded: boolean
    },
    next_step: string,
    validation_tokens: {
        phone_validation?: string;
        email_validation?: string;
    },
    alert: string | null,
    reset_success: boolean,
    show_reset_link: boolean
}

const initState: AuthInterface = {
    error_message: null,
    profile: {
        isEmpty: true,
        isLoaded: false,
    },
    next_step: 'register',
    validation_tokens: {},
    alert: null,
    reset_success: false,
    show_reset_link: false,
};

export enum AuthReducerActionType {
    FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS",
    APPLY_VALIDATION_TOKENS = "APPLY_VALIDATION_TOKENS",
    SIGNIN_SUCCESS = "SIGNIN_SUCCESS",
    SIGNIN_ERROR = "SIGNIN_ERROR",
    SIGNUP_SUCCESS = "SIGNUP_SUCCESS",
    SIGNUP_ERROR = "SIGNUP_ERROR",
    NEXT_STEP = "NEXT_STEP",
    FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR",
    RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR",
    CONFIRMATION_ERROR = "CONFIRMATION_ERROR",
    CONSENT_ERROR = "CONSENT_ERROR",
    RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
    REMOVE_ERRORS = "REMOVE_ERRORS",
}

interface AuthReducerActionInterface {
    type: AuthReducerActionType,
    profile?: { id: string, name: { first: string, last: string }, email: string },
    error_message?: string,
    next_step: string,
    validation_tokens: {
        phone_validation?: string;
        email_validation?: string;
    }
    alert?: any,
    show_reset_link: boolean,
}

const authReducer = (state = initState, action: AuthReducerActionInterface) => {
    switch (action.type) {
        case AuthReducerActionType.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                alert: action.alert,
            };
        case AuthReducerActionType.APPLY_VALIDATION_TOKENS:
            localStorage.setItem("validation_tokens", JSON.stringify(action.validation_tokens));
            return {
                ...state,
                validation_tokens: action.validation_tokens,
                alert: null
            };
        case AuthReducerActionType.SIGNIN_SUCCESS:
            return {
                ...state,
                error_message: null,
                profile: {isEmpty: false, isLoaded: true, ...action.profile},
                alert: null

            };
        case AuthReducerActionType.SIGNIN_ERROR:
            return {
                ...state,
                error_message: action.error_message,
                profile: {isEmpty: true, isLoaded: false},
                alert: null
            };
        case AuthReducerActionType.SIGNUP_SUCCESS:
            return {
                ...state,
                error_message: null,
                profile: {isEmpty: true, isLoaded: true},
                validation_tokens: action.validation_tokens,
                next_step: action.next_step,
                alert: null
            };
        case AuthReducerActionType.SIGNUP_ERROR:
            return {
                ...state,
                error_message: action.error_message,
                profile: {isEmpty: true, isLoaded: false},
                alert: null,
                show_reset_link: action.show_reset_link,
            };
        case AuthReducerActionType.NEXT_STEP:
            return {
                ...state,
                next_step: action.next_step,
                error_message: null,
                alert: null
            };
        case AuthReducerActionType.FORGOT_PASSWORD_ERROR:
        case AuthReducerActionType.RESET_PASSWORD_ERROR:
        case AuthReducerActionType.CONSENT_ERROR:
        case AuthReducerActionType.CONFIRMATION_ERROR:
            return {
                ...state,
                error_message: action.error_message,
                alert: null,
                reset_success: false
            };
        case AuthReducerActionType.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                alert: null,
                reset_success: true,
            };
        case AuthReducerActionType.REMOVE_ERRORS:
            return {
                ...state,
                alert: null,
                error_message: null,
                show_reset_link: false,
            };
        default:
            return state;
    }
};

export default authReducer;
