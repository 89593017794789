import {TranslatableStringInterface} from 'interfaces/translatable-string.interface';

export const translationHelper = (translatable: String | TranslatableStringInterface | undefined, language: string): string => {
    if (typeof translatable === "string") {
        return translatable;
    }
    if (typeof translatable === "object") {
        translatable = translatable as TranslatableStringInterface;
        if (translatable[language]) {
            return translatable[language]
        }
        return translatable["fi"]
    }

    return ""
};