import React, {RefAttributes} from "react";
import ErrorComponent from "./error.component";

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
    value: any,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    errors?: any,
    label?: string
    field?: string
}

const TextInput: React.ForwardRefExoticComponent<React.PropsWithoutRef<TextInputProps> & React.RefAttributes<unknown>> = React.forwardRef((props: TextInputProps, ref:any) => {

    const {id, type, autoComplete, value, onChange, errors, label, field, className, style, required, ...inputProps} = props;

    const errorClasses = "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:border-red-300 focus:shadow-outline-red border-red-300 text-red-900 placeholder-red-300";
    const normalClasses = "form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5";

    return <div>
        {label && <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700">
            {label} {required && <span className="text-red-600">*</span>}
        </label>}
        <div className="mt-1 rounded-md shadow-sm relative">
            <input
                id={id}
                type={type}
                className={errors && field && errors[field] ? errorClasses + " " + className : normalClasses + " " + className}
                autoComplete={autoComplete}
                value={value}
                onChange={onChange}
                required={required}
                ref={ref}
                style={style}
                {...inputProps}
            />
            {errors && field && errors[field] &&
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"/>
                </svg>
            </div>}
        </div>
        <ErrorComponent errors={errors} field={field || ""}/>
    </div>
});

export default TextInput;
