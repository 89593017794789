import React from "react";
import MicrosoftLogin from "react-microsoft-login";
import {AuthError, AuthResponse} from "msal";

interface MicrosoftLoginButtonProps {

}

const MicrosoftLoginButton: React.FC<MicrosoftLoginButtonProps> = props => {

    const authHandler = (error?: AuthError, data?: AuthResponse) => {
        console.log(error, data)
    }

    return <MicrosoftLogin
        clientId={process.env.REACT_APP_MICROSOFT_APP_ID || ""}
        authCallback={authHandler}
        withUserData={false}
    >
        <span className="w-full inline-flex rounded-md shadow-sm">
            <button
                type="button"
                className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                aria-label="Kirjaudu Microsoft-tilillä"
            >
                <svg className="h-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                    <path
                        d="M11.4 24H0V12.6h11.4V24zM24 24H12.6V12.6H24V24zM11.4 11.4H0V0h11.4v11.4zm12.6 0H12.6V0H24v11.4z"/>
                </svg>
            </button>
        </span>
    </MicrosoftLogin>;
}

export default MicrosoftLoginButton;
