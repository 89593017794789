import axios from "axios";
import 'url-search-params-polyfill';
import {ConfigReducerActionType} from "store/reducers/config.reducer";

const urlParams = new URLSearchParams(window.location.search);

const fetchLoginChallengeFromQuery = () => {
    return urlParams.get('login_challenge');
};

export const loadUI = () => async (dispatch: any) => {
    let loginChallenge = fetchLoginChallengeFromQuery();

    /*
    // Jos local storagesta löytyy config, jatketaan vanhoilla tiedoilla
    if (loginChallenge === null && localStorage.getItem("config")) return dispatch({
        type: 'APPLY_CONFIG',
        config: JSON.parse(localStorage.getItem("config") || "{}")
    });*/

    // Jos löytyy login challenge, tyhjennetään localstorage ja haetaan uudet tiedot
    if (loginChallenge) {
        localStorage.clear()
    }

    // Jos token on annettu, annetaan default config
    if (loginChallenge === null && urlParams.get("token")) {
        return dispatch({
            type: ConfigReducerActionType.APPLY_CONFIG,
            config: {
                "available_login_methods": ["uid_pw"],
                "available_password_recovery_methods": ["email", "external_id+phone"],
                "allow_register": true,
                "allow_remember_me": false,
                "default_view": "signin",
                "ui_settings": {"primary_color": null, "secondary_color": null, "background_image_url": null},
                "uid_labels": {"fi": "Sähköpostiosoite tai asiakasnumero", "en": "E-mail address or customer number"},
                "external_id_labels": {"fi": "Asiakasnumero", "en": "Customer number"}
            }
        })
    }

    if (loginChallenge === null && !urlParams.get("token")) {
        loginChallenge = localStorage.getItem("login_challenge")
    }

    try {
        const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/login/begin-login-process', {login_challenge: loginChallenge})
        if (res.data.redirect_to) return window.location.href = res.data.redirect_to;
        res.data.login_challenge = loginChallenge;
        dispatch({type: ConfigReducerActionType.APPLY_CONFIG, config: res.data});
    } catch (error) {
        dispatch({type: ConfigReducerActionType.APPLY_CONFIG_ERROR, error})
    }
};

export const firstTimeLoad = () => (dispatch: any) => {
    dispatch({type: ConfigReducerActionType.FIRST_TIME_LOAD})
};

// TODO: Validate token
export const validateToken = () => async (dispatch: any) => {
    try {
        //const res = await axios.post(process.env.REACT_APP_API_ENDPOINT + '/account/validation_token_info', {token: urlParams.get("token")});
    } catch (error) {
        console.log(error)
    }
};
