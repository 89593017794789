import {
    IsEmail,
    IsOptional,
    IsPhoneNumber,
    IsString,
    Matches, MaxLength, MinLength,
    ValidateIf
} from "class-validator";
import {Type} from "class-transformer";
import {Match} from "interfaces/match.decorator";

export class Profile {
    constructor(first_name: string, last_name: string, phone: string, email: string, street_address: string, city: string, postal_code: string, country_code: string, password: string, confirmed_password: string) {
        this.first_name = first_name;
        this.last_name = last_name;
        this.phone = phone;
        this.email = email;
        this.street_address = street_address;
        this.city = city;
        this.postal_code = postal_code;
        this.country_code = country_code;
        this.password = password;
        this.confirmed_password = confirmed_password;
    }

    @IsString({
        message: "Tarkista etunimesi."
    })
    @MinLength(1, {
        message: "Tarkista etunimesi."
    })
    @IsOptional()
    first_name?: string;

    @IsString({
        message: "Tarkista sukunimesi."
    })
    @MinLength(1, {
        message: "Tarkista sukunimesi."
    })
    @IsOptional()
    last_name?: string;

    @IsPhoneNumber("FI", {
        message: "Puhelinnumerosi on virheellinen."
    })
    @IsOptional()
    phone?: string;

    @IsEmail({}, {
        message: "Sähköpostiosoitteesi on virheellinen."
    })
    @IsOptional()
    email?: string;

    @ValidateIf(o => !!o.city || !!o.postal_code, {
        message: "Tarkista osoitteesi."
    })
    @IsString({
        message: "Tarkista osoitteesi."
    })
    @MinLength(2, {
        message: "Tarkista osoitteesi."
    })
    street_address?: string;

    @ValidateIf(o => !!o.city || !!o.street_address, {
        message: "Postinumerosi on virheellinen."
    })
    @IsString()
    @Matches(/^[0-9]{5}$/, {
        message: "Postinumerosi on virheellinen."
    })
    @Type(() => String)
    postal_code?: string;

    @ValidateIf(o => !!o.street_address || !!o.postal_code, {
        message: "Tarkista postitoimipaikkasi."
    })
    @IsString()
    @MinLength(2, {
        message: "Tarkista postitoimipaikkasi."
    })
    city?: string;

    @IsOptional()
    @IsString({
        message: "Tarkista asuinmaasi."
    })
    @MinLength(2, {
        message: "Tarkista asuinmaasi."
    })
    @MaxLength(2, {
        message: "Tarkista asuinmaasi."
    })
    country_code?: string;

    @IsString()
    @MinLength(8, {message: "Salasanan minimipituus on 8 merkkiä."})
    password: string | undefined;

    @IsString()
    @Match("password", {message: "Salasanat eivät täsmää."})
    confirmed_password: string | undefined;
}