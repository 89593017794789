import React, {FormEvent, useEffect} from "react";
import Layout from "components/layout.component";
import {ConfigInterface} from "interfaces/config.interface";
import {connect} from "react-redux";
import {signIn, signInWithFacebook, signInWithGoogle} from "store/actions/auth.actions";
import {useTranslation} from "react-i18next";
import {translationHelper} from "helpers/translation.helper";
import {Link, RouteComponentProps} from "react-router-dom";
import SocialButton from "../utils/social-button.component";
import MicrosoftLoginButton from "../utils/microsoft-login-button.component";
import {AppState} from "store/reducers";
import {AuthInterface} from "store/reducers/auth.reducer";

interface SignInPageProps extends RouteComponentProps {
    auth: AuthInterface,
    config: ConfigInterface,
    signIn: (uid: string, password: string) => void,
    signInWithGoogle: (token: string) => void,
    signInWithFacebook: (token: string) => void,
}

const SignInPage: React.FC<SignInPageProps> = (props: SignInPageProps) => {

    const {t, i18n} = useTranslation();

    const [uid, setUid] = React.useState("");
    const [password, setPassword] = React.useState("");

    const {config, signIn, history, signInWithGoogle, signInWithFacebook, auth} = props;

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        signIn(uid, password);
    };

    useEffect(() => {
        if (config.isLoaded && !config.isEmpty) {
			// Jos meneillään olevan login_challengen default view on jo käsitelty, skipataan
			// (jos /signin ladataan uudelleen samalla login_challengella)
			const handledDefaultView = sessionStorage.getItem('handled_default_view_login_challenge') || '';
			if (handledDefaultView === config.login_challenge) {
				console.log('Default view is handled', config.login_challenge);
				return;
			}
            if (config.default_view === "registration") {
                history.push('/signup');
            }
            if (config.default_view === "forgotten_password") {
                history.push('/forgot-password');
            }
			console.log('Setting default view handled', config.login_challenge);
			sessionStorage.setItem('handled_default_view_login_challenge', config.login_challenge || '');
        }
    }, [config, history]);

    return <Layout>
        {auth.error_message && <div className="w-full mb-4">
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                <p className="font-bold">{t("common.error_occurred")}</p>
                <p>{auth.error_message}</p>
                {auth.show_reset_link &&
                <Link to="/forgot-password" className="mt-2">Voit palauttaa
                    salasanasi <b>täältä</b>!</Link>}
            </div>
        </div>}
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {config.available_login_methods && config.available_login_methods.includes('uid_pw') &&
            <form onSubmit={event => handleSubmit(event)}>
                <div>
                    <label htmlFor="uid" className="block text-sm font-medium leading-5 text-gray-700">
                        {translationHelper(config.uid_labels, i18n.language)}
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                        <input
                            id="uid"
                            type="text"
                            required={true}
                            value={uid}
                            onChange={event => setUid(event.target.value)}
                            autoComplete="email"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
                    </div>
                </div>

                <div className="mt-6">
                    <label htmlFor="password" className="block text-sm font-medium leading-5 text-gray-700">
                        {t("common.field.password")}
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                        <input
                            id="password"
                            type="password"
                            required={true}
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            autoComplete="current-password"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"/>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-between">
                    {config.allow_remember_me &&
                    <div className="flex items-center">
                        <input id="remember_me" type="checkbox"
                               className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"/>
                        <label htmlFor="remember_me" className="ml-2 block text-sm leading-5 text-gray-900">
                            {t("common.field.remember_me")}
                        </label>
                    </div>}

                    <div className="text-sm leading-5">
                        <Link to="/forgot-password"
                              className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                            {t("common.forgot_password")}
                        </Link>
                    </div>
                </div>

                <div className="mt-6">
                  <span className="block w-full rounded-md shadow-sm">
                    <button type="submit"
                            className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                      {t("common.signin")}
                    </button>
                  </span>
                </div>
            </form>}
            {config.allow_register && <div className="mt-2">
                  <span className="block w-full rounded-md shadow-sm">
                    <Link to="/signup"
                          className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out">
                      {t("common.no_account_yet")}
                    </Link>
                  </span>
            </div>}

            {config.available_login_methods?.length > 1 && <div className="mt-6">
                <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300"/>
                    </div>
                    <div className="relative flex justify-center text-sm leading-5">
                        <span className="px-2 bg-white text-gray-500">
                          {t("common.or_use")}
                        </span>
                    </div>
                </div>

                <div className="mt-6 grid grid-cols-3 gap-3">
                    {config.available_login_methods.includes('facebook') &&
                    <SocialButton
                        provider='facebook'
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
                        onLoginSuccess={(res: any) => signInWithFacebook(res._token.accessToken)}
                        onLoginFailure={(error: any) => console.log("FB Failed", error)}
                    >
                        <svg className="h-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M23.9981 11.9991C23.9981 5.37216 18.626 0 11.9991 0C5.37216 0 0 5.37216 0 11.9991C0 17.9882 4.38789 22.9522 10.1242 23.8524V15.4676H7.07758V11.9991H10.1242V9.35553C10.1242 6.34826 11.9156 4.68714 14.6564 4.68714C15.9692 4.68714 17.3424 4.92149 17.3424 4.92149V7.87439H15.8294C14.3388 7.87439 13.8739 8.79933 13.8739 9.74824V11.9991H17.2018L16.6698 15.4676H13.8739V23.8524C19.6103 22.9522 23.9981 17.9882 23.9981 11.9991Z"/>
                        </svg>
                    </SocialButton>
                    }

                    {config.available_login_methods.includes('microsoft') &&
                        <MicrosoftLoginButton/>
                    }

                    {config.available_login_methods.includes('google') &&
                    <SocialButton
                        provider='google'
                        appId={process.env.REACT_APP_GOOGLE_APP_ID || ""}
                        onLoginSuccess={(res: any) => signInWithGoogle(res._token.idToken)}
                    >
                        <svg className="h-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                            <path
                                d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"/>
                        </svg>
                    </SocialButton>
                    }
                </div>
            </div>}
        </div>
    </Layout>
};

const mapStateToProps = (state: AppState) => ({
    auth: state.auth,
    config: state.config
});

const mapDispatchToProps = (dispatch: any) => ({
    signIn: (uid: string, password: string) => dispatch(signIn(uid, password)),
    signInWithGoogle: (token: string) => dispatch(signInWithGoogle(token)),
    signInWithFacebook: (token: string) => dispatch(signInWithFacebook(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
