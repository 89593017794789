import React, {createRef, FormEvent, useEffect} from "react";
import TextInput from "components/utils/text-input.component";
import {useTranslation} from "react-i18next";

const VerifyForm = (props: any) => {

    const {t} = useTranslation();

    const {code} = props;
    const [typeFields, setTypeFields] = React.useState([
        {
            value: '',
            ref: createRef<any>(),
        },
        {
            value: '',
            ref: createRef<any>(),
        },
        {
            value: '',
            ref: createRef<any>(),
        },
        {
            value: '',
            ref: createRef<any>(),
        },
        {
            value: '',
            ref: createRef<any>(),
        },
        {
            value: '',
            ref: createRef<any>(),
        }
    ]);

    const handleChange = (i: number, e: any) => {
        const array = [...typeFields];
        array[i] = {ref: typeFields[i].ref, value: e.target.value};
        setTypeFields(array);


        if (i === 5) {
            typeFields[i].ref.current.blur();
            props.validation(array.map(field => field.value).join(""));
        }

        if (i < 5) {
            typeFields[i + 1].ref.current.focus();
        }
        e.preventDefault();
    };

    const submitForm = (event: FormEvent) => {
        const c = typeFields.map(field => field.value).join("");
        if (c.length === 6) {
            props.validation(c);
        }
        event.preventDefault();
    };

    useEffect(() => {
        if (code.length === 6) {
            const c = code.split('');
            const array = [...typeFields];
            array[0] = {ref: typeFields[0].ref, value: c[0]};
            array[1] = {ref: typeFields[1].ref, value: c[1]};
            array[2] = {ref: typeFields[2].ref, value: c[2]};
            array[3] = {ref: typeFields[3].ref, value: c[3]};
            array[4] = {ref: typeFields[4].ref, value: c[4]};
            array[5] = {ref: typeFields[5].ref, value: c[5]};
            setTypeFields(array);
        }
    }, [code, typeFields]);


    return <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div className="font-bold text-2xl mb-2 text-gray-700">{props.title}</div>
        <p className="font-normal text-sm mb-2 text-gray-700">{props.text}</p>
        <form onSubmit={event => submitForm(event)}>
            <div className="flex flex-wrap justify-center -mx-2">
                {[0, 1, 2, 3, 4, 5].map(number => <div className="w-1/6 p-2" key={number}>
                    <TextInput
                        id="code_field_0"
                        type="number"
                        value={typeFields[number].value}
                        onChange={event => handleChange(number, event)}
                        maxLength={1}
                        minLength={1}
                        ref={typeFields[number].ref}
                        className={"text-center"}
                        style={{MozAppearance: "textfield"}}
                    />
                </div>)}
                <div className="w-1/4 mt-4">
                    <button
                        onClick={event => submitForm(event)}
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                        {t("common.check")}
                    </button>
                </div>
            </div>
        </form>
    </div>;
};

export default VerifyForm;
