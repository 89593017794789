import React, {FormEvent} from "react";
import {connect} from "react-redux";
import Layout from "../layout.component";
import {forgotPassword, forgotUuaPassword, resetPassword} from "store/actions/auth.actions";
import {loadUI} from "store/actions/config.actions";
import TextInput from "components/utils/text-input.component";
import {RouteComponentProps, withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {AppState} from "store/reducers";
import {ConfigInterface} from "interfaces/config.interface";
import {AuthInterface} from "store/reducers/auth.reducer";

interface ResetPasswordProps {
    resetPassword: (password: string, confirmed_password: string) => void
}

const ResetPassword: React.FC<ResetPasswordProps> = props => {

    const {t} = useTranslation();

    const [password, setPassword] = React.useState("");
    const [confirmedPassword, setConfirmedPassword] = React.useState("");

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        props.resetPassword(password, confirmedPassword)
    };

    return <form onSubmit={event => handleSubmit(event)}>
        <div className="mb-4">
            <TextInput
                id="password"
                type="password"
                autoComplete="new-password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                required={true}
                minLength={8}
                label={t("common.field.new_password")}
            />
        </div>
        <div className="mb-4">
            <TextInput
                id="confirm-password"
                type="password"
                autoComplete="new-password"
                value={confirmedPassword}
                onChange={event => setConfirmedPassword(event.target.value)}
                required={true}
                minLength={8}
                label={t("common.field.confirm_password")}
            />
        </div>
        <span className="block w-full rounded-md shadow-sm">
           <button
               type="submit"
               className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
             {t("password.return")}
           </button>
        </span>
    </form>

};

interface EmailFormProps {
    forgotPassword: (email: string) => void,
    setSelectedMethod: (method: string) => void
}

const EmailForm: React.FC<EmailFormProps> = props => {

    const {t} = useTranslation();

    const [email, setEmail] = React.useState("");

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        props.forgotPassword(email)
    };

    return <form onSubmit={event => handleSubmit(event)}>
        <div className="mb-4">
            <TextInput
                id="email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
                required={true}
                label={t("common.field.email")}
            />
        </div>
        <span className="block w-full rounded-md shadow-sm">
           <button
               type="submit"
               className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
             {t("password.return")}
           </button>
        </span>
        <span className="block w-full rounded-md shadow-sm mt-2">
            <button
                type="button"
                onClick={() => props.setSelectedMethod("")}
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out">
              {t("common.back")}
            </button>
        </span>
    </form>
};
interface UUAFormProps {
    forgotUuaPassword: (phone: string, uuaId: string) => void,
    config: ConfigInterface,
    setSelectedMethod: (method: string) => void
}
const UUAForm: React.FC<UUAFormProps> = props  => {

    const [phone, setPhone] = React.useState("");
    const [uuaId, setUuaId] = React.useState("");

    const {t, i18n} = useTranslation();


    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        props.forgotUuaPassword(phone, uuaId)
    };

    return <form onSubmit={event => handleSubmit(event)}>
        <div className="mb-4">
            <TextInput
                id="uua"
                type="text"
                value={uuaId}
                onChange={event => setUuaId(event.target.value)}
                required={true}
                label={props.config.external_id_labels[i18n.language]}
            />
        </div>
        <div className="mb-4">
            <TextInput
                id="phone"
                type="tel"
                autoComplete="tel"
                value={phone}
                onChange={event => setPhone(event.target.value)}
                required={true}
                label={t("common.field.phone")}
            />
        </div>
        <span className="block w-full rounded-md shadow-sm">
           <button
               type="submit"
               className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
             {t("password.return")}
           </button>
        </span>
        <span className="block w-full rounded-md shadow-sm mt-2">
            <button
                type="button"
                onClick={() => props.setSelectedMethod("")}
                className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out">
              {t("common.back")}
            </button>
        </span>
    </form>
};
interface Match {
    path: string;
}

interface ForgotPasswordPageProps extends RouteComponentProps<Match> {
    loadUI: () => void,
    auth: AuthInterface
    config: ConfigInterface,
    resetPassword: (password: string, confirmed_password: string) => void,
    forgotPassword: (email: string) => void,
    forgotUuaPassword: (phone: string, uuaId: string) => void,
}

const ForgotPasswordPage: React.FC<ForgotPasswordPageProps> = props => {

    const {t} = useTranslation();

    const [showReset, setShowReset] = React.useState(false);
    const [selectedMethod, setSelectMethod] = React.useState("");

    const {match, loadUI, auth} = props;

    React.useEffect(() => {
        loadUI();
        match.path === "/reset_password" ? setShowReset(true) : setShowReset(false);
    }, [loadUI, match]);

    const methods = props.config.available_password_recovery_methods;
    return <Layout>
        {auth.error_message && <div className="w-full mb-4">
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                <p className="font-bold">{t("common.error_occurred")}</p>
                <p>{auth.error_message}</p>
            </div>
        </div>}
        {auth.alert && <div className="w-full mb-4">
            <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
                <p className="font-bold">{t("forgot_password.sent")}</p>
                <p>{auth.alert}</p>
            </div>
        </div>}

        {auth.reset_success && <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="font-bold text-2xl mb-2 text-gray-700">{t("password.changed.title")}</div>
            <p className="font-normal text-sm mb-2 text-gray-700">{t("password.changed.text")}</p>
        </div>}
        {!auth.reset_success && <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="font-bold text-2xl mb-2 text-gray-700">{t("forgot_password.title")}</div>
            {showReset ?
                <ResetPassword resetPassword={props.resetPassword}/> :
                <div>
                    {!selectedMethod ? <div>
                        {methods && methods.includes("email") &&
                        <span className="block w-full rounded-md shadow-sm mb-2">
                               <button
                                   onClick={() => setSelectMethod("email")}
                                   type="button"
                                   className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                  Käyttäen sähköpostia
                               </button>
                            </span>}
                        {methods && methods.includes("external_id+phone") &&
                        <span className="block w-full rounded-md shadow-sm">
                               <button
                                   onClick={() => setSelectMethod("external_id+phone")}
                                   type="button"
                                   className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                  Käyttäen asiakasnumeroa
                               </button>
                            </span>
                        }
                        <span className="block w-full rounded-md shadow-sm mt-2">
                                <Link
                                    to="/signin"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out">
                                  {t("common.back")}
                                </Link>
                            </span>
                    </div> : selectedMethod === "external_id+phone" ?
                        <UUAForm config={props.config} forgotUuaPassword={props.forgotUuaPassword}
                                 setSelectedMethod={setSelectMethod}/> :
                        <EmailForm forgotPassword={props.forgotPassword} setSelectedMethod={setSelectMethod}/>}
                </div>
            }
        </div>}
    </Layout>
};

const mapStateToProps = (state: AppState) => ({
    auth: state.auth,
    config: state.config
});

const mapDispatchToProps = (dispatch: any) => ({
    forgotPassword: (email: string) => dispatch(forgotPassword(email)),
    forgotUuaPassword: (phone: string, uuaId: string) => dispatch(forgotUuaPassword(phone, uuaId)),
    resetPassword: (password: string, confirmed_password: string) => dispatch(resetPassword(password, confirmed_password)),
    loadUI: () => dispatch(loadUI())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPasswordPage))
