import React from "react";
import {connect} from "react-redux";
import {nextStep, validateEmail, validatePhone} from "store/actions/auth.actions";
import {loadUI, validateToken} from "store/actions/config.actions";
import Layout from "components/layout.component";
import SignUpForm from "./signup-form.component";
import VerifyForm from "./methods/verify.component";
import Done from "./methods/done.component";
import {useTranslation} from "react-i18next";
import 'url-search-params-polyfill';
import {RouteComponentProps} from "react-router";
import {ConfigInterface} from "interfaces/config.interface";
import {AuthInterface} from "store/reducers/auth.reducer";
import {AppState} from "store/reducers";
import {Link} from "react-router-dom";

interface MatchParams {
    type: string;
    0: string
}

interface SignUpPageProps extends RouteComponentProps<MatchParams> {
    loadUI: () => void,
    nextStep: (step: string) => void,
    validateToken: () => void,
    validatePhone: (phone: string) => void
    validateEmail: (email: string) => void
    config: ConfigInterface,
    auth: AuthInterface
}

const SignUpPage: React.FC<SignUpPageProps> = props => {

    const {t} = useTranslation();

    const [confirmationCode, setConfirmationCode] = React.useState("");

    const {loadUI, nextStep, match, history, validateToken, config, auth} = props;

    React.useEffect(() => {
        if (match.params[0] === "confirm") {
            const urlParams = new URLSearchParams(window.location.search);
            switch (match.params.type) {
                case 'email':
                    validateToken();
                    nextStep('email_validation');
                    setConfirmationCode(urlParams.get('code') || '');
                    break;
                case 'phone':
                    nextStep('phone_validation');
                    setConfirmationCode(urlParams.get('code') || '');
                    break;
                default:
                    nextStep('register');
                    history.push('/signup');
                    break;
            }

        }

        if (localStorage.getItem("next_step")) {
            nextStep(localStorage.getItem("next_step") || "")
        }
    }, [loadUI, nextStep, match, history, validateToken]);

    if (!config.isLoaded || !config.allow_register) return <p>Ladataan...</p>;

    return <Layout>
        {auth.error_message && <div className="w-full mb-4">
            <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                <p className="font-bold">{t("common.error_occurred")}</p>
                <p>{auth.error_message}</p>
                {auth.show_reset_link &&
                <Link to="/forgot-password" className="mt-2">Voit palauttaa
                    salasanasi <b>täältä</b>!</Link>}
            </div>
        </div>}
        {auth.next_step === "register" && <SignUpForm/>}
        {auth.next_step === "phone_validation" &&
        <VerifyForm validation={props.validatePhone}
                    title={t("signup.confirm.phone.title")}
                    text={t("signup.confirm.phone.text")}
                    code={confirmationCode}/>}
        {auth.next_step === "email_validation" &&
        <VerifyForm validation={props.validateEmail}
                    title={t("signup.confirm.email.title")}
                    text={t("signup.confirm.email.text")}
                    code={confirmationCode}/>}
        {auth.next_step === "done" && <Done/>}
    </Layout>;
};

const mapStateToProps = (state: AppState) => ({
    config: state.config,
    auth: state.auth
});

const mapDispatchToProps = (dispatch: any) => ({
    validatePhone: (code: string) => dispatch(validatePhone(code)),
    validateEmail: (code: string) => dispatch(validateEmail(code)),
    validateToken: () => dispatch(validateToken()),
    nextStep: (next_step: string) => dispatch(nextStep(next_step)),
    loadUI: () => dispatch(loadUI())
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
