import React, {FormEvent} from 'react';
import TextInput from "components/utils/text-input.component";
import {withRouter} from "react-router";
import {signUp} from "store/actions/auth.actions";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {validate} from "class-validator";
import {Profile} from "classes/profile.class";

const SignUpForm = (props: any) => {

    const [errors, setErrors] = React.useState<any>({});

    const {t} = useTranslation();
    const [first_name, setFirstname] = React.useState("");
    const [last_name, setLastname] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [street_address, setStreetAddress] = React.useState("");
    const [postal_code, setPostalCode] = React.useState("");
    const [city, setCity] = React.useState("");
    const [country, setCountry] = React.useState("FI");
    const [password, setPassword] = React.useState("");
    const [confirmed_password, setConfirmedPassword] = React.useState("");

    const validateProfile = async (): Promise<any> => {
        const collectedErrors: any = {};
        const profile = new Profile(first_name, last_name, phone, email, street_address, city, postal_code, country, password, confirmed_password);

        return new Promise((resolve, reject) => {
            validate(profile).then(validationErrors => {
                validationErrors.forEach(error => {
                    if (error.constraints) {
                        const constraint = Object.keys(error.constraints)[0];
                        collectedErrors[error.property] = error.constraints[constraint];
                    }
                });
                setErrors(collectedErrors);
                resolve(collectedErrors);
            });
        });
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        const validatedResult = await validateProfile();
        if (Object.keys(validatedResult).length > 0) {
            return;
        }
        props.signUp(new Profile(first_name, last_name, phone, email, street_address, city, postal_code, country, password, confirmed_password));
    };


    return <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div className="font-bold text-2xl mb-2 text-gray-700">{t("signup.title")}</div>
        <p className="font-normal text-sm mb-2 text-gray-700 mb-6">{t("signup.text")}</p>
        <form onSubmit={event => handleSubmit(event)}>
            <div className="mb-4">
                <TextInput
                    id="first_name"
                    type="text"
                    value={first_name}
                    onChange={event => setFirstname(event.target.value)}
                    required={true}
                    label={t("common.field.first_name")}
                    autoComplete="given-name"
                    errors={errors}
                    field="first_name"
                />
            </div>
            <div className="mb-4">
                <TextInput
                    id="last_name"
                    type="text"
                    value={last_name}
                    onChange={event => setLastname(event.target.value)}
                    required={true}
                    label={t("common.field.last_name")}
                    autoComplete="family-name"
                    errors={errors}
                    field="last_name"
                />
            </div>
            <div className="mb-4">
                <TextInput
                    id="phone"
                    type="phone"
                    value={phone}
                    onChange={event => setPhone(event.target.value)}
                    required={false}
                    label={t("common.field.phone")}
                    autoComplete="tel-local"
                    errors={errors}
                    field="phone"
                />
            </div>
            <div className="mb-4">
                <TextInput
                    id="email"
                    type="email"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                    required={true}
                    label={t("common.field.email")}
                    autoComplete="email"
                    errors={errors}
                    field="email"
                />
            </div>
            <div className="mb-4">
                <TextInput
                    id="password"
                    type="password"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                    required={true}
                    label={t("common.field.password")}
                    autoComplete="new-password"
                    minLength={8}
                    errors={errors}
                    field="password"
                />
            </div>
            <div className="mb-4">
                <TextInput
                    id="confirmed_password"
                    type="password"
                    value={confirmed_password}
                    onChange={event => setConfirmedPassword(event.target.value)}
                    required={true}
                    label={t("common.field.confirm_password")}
                    autoComplete="new-password"
                    minLength={8}
                    errors={errors}
                    field="confirmed_password"
                />
            </div>
            <div className="mb-2">
                <TextInput
                    label="Osoite"
                    autoComplete="address-line1"
                    id="street_address"
                    type="text"
                    required={true}
                    value={street_address}
                    onChange={event => setStreetAddress(event.target.value)}
                    errors={errors}
                    field="street_address"
                />
            </div>
            <div className="mb-4">
                <TextInput
                    label="Postinumero"
                    autoComplete="postal-code"
                    id="postal_code"
                    type="text"
                    required={true}
                    value={postal_code}
                    onChange={event => setPostalCode(event.target.value)}
                    errors={errors}
                    field="postal_code"
                />
            </div>
            <div className="mb-4">
                <TextInput
                    label="Toimipaikka"
                    autoComplete="city"
                    id="city"
                    type="text"
                    required={true}
                    value={city}
                    onChange={(event: any) => setCity(event.target.value)}
                    errors={errors}
                    field="city"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="country" className="block text-sm font-medium leading-5 text-gray-700">
                    Maa
                </label>
                <div className="mt-1 rounded-md shadow-sm">
                    <div className="w-full rounded-md shadow-sm">
                        <select
                            id="country"
                            className="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            autoComplete="country-name"
                            required={true}
                            onChange={event => setCountry(event.target.value)}
                            value={country}
                        >
                            <option value="FI">Suomi</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mt-6">
                <span className="block w-full rounded-md shadow-sm">
                   <button
                       type="submit"
                       className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                     {t("common.signup")}
                   </button>
                </span>
            </div>
            <div className="mt-2">
                <span className="block w-full rounded-md shadow-sm">
                    <Link
                        to="/signin"
                        className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition duration-150 ease-in-out">
                  {t("common.have_an_account")}
                </Link>
              </span>
            </div>
        </form>
    </div>;
};

const mapDispatchToProps = (dispatch: any) => ({
    signUp: (data: {
        first_name: string, last_name: string, email: string, phone: string, password: string,
        confirmed_password: string,
        address: {
            street_address: string,
            city: string,
            postal_code: string,
            country_code: string
        }
    }) => dispatch(signUp(data)),
});

export default connect(null, mapDispatchToProps)(withRouter(SignUpForm));
