import React from "react";
import {connect} from "react-redux";
import {consent} from "store/actions/auth.actions";

interface ConsentPageProps {
    consent: () => void
}

const ConsentPage: React.FC<ConsentPageProps> = (props: ConsentPageProps) => {
    const {consent} = props;
    React.useEffect(() => {
        consent();
    }, [consent]);
    return <p>Uudelleenohjataan...</p>
};

const mapDispatchToProps = (dispatch: any) => ({
    consent: () => dispatch(consent())
});

export default connect(null, mapDispatchToProps)(ConsentPage);
