import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: 'fi',
        backend: {
            loadPath: window.location.protocol + "//" + window.location.host + '/assets/i18n/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'fi',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },
        useSuspense: true
    });

export default i18n
