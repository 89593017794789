import "reflect-metadata";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from "react-redux";
import {applyMiddleware, createStore} from "redux";
import reducer from './store/reducers';
import thunk from "redux-thunk";
import './index.css'
import './styles/tailwind.css';
import i18n from './i18n'
import {I18nextProvider} from "react-i18next";
import ReactGA from 'react-ga';
import { save, load } from "redux-localstorage-simple"

const createStoreWithMiddleware = applyMiddleware(thunk, save({ states: ["config"], namespace: "eventio_account"}))(createStore)

const store = createStoreWithMiddleware(reducer, load({ namespace: "eventio_account"}))

if (process.env.NODE_ENV === "development") {
    store.subscribe(() => {
        console.log("Updated", store.getState());
    });
} else {
    ReactGA.initialize('UA-4114512-10');
    ReactGA.pageview(window.location.pathname + window.location.search);
}



ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <App/>
        </Provider>
    </I18nextProvider>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
