import React, {Suspense, useEffect} from 'react';
import {Route} from "react-router";
import {BrowserRouter} from "react-router-dom";
import Consent from "components/consent.page";
import SignoutPage from "components/auth/signout.page";
import SignInPage from "components/auth/signin.page";
import {loadUI} from "store/actions/config.actions";
import {connect} from "react-redux";
import SignUpPage from "components/auth/signup.page";
import ForgotPasswordPage from "components/auth/forgot-password.page";

interface AppProps {
    loadUI: () => void,
}

const App: React.FC<AppProps> = (props: AppProps) => {

    const {loadUI} = props;

    useEffect(() => {
        loadUI();
    }, [loadUI]);

    return <BrowserRouter>
        <Suspense fallback={<p>Ladataan...</p>}>
            <Route path="/" exact component={SignInPage}/>
            <Route path="/forgot-password" exact component={ForgotPasswordPage}/>
            <Route path="/reset_password" exact component={ForgotPasswordPage}/>
            <Route path="/signin" exact component={SignInPage}/>
            <Route path="/signup/(confirm)?/:type?" exact component={SignUpPage}/>
            <Route path="/signout" exact component={SignoutPage}/>
            <Route path="/consent" exact component={Consent}/>
        </Suspense>
    </BrowserRouter>
};

const mapDispatchToProps = (dispatch: any) => ({
    loadUI: () => dispatch(loadUI())
});

export default connect(null, mapDispatchToProps)(App);
